.select_container {
  position: relative;
  display: flex;
  align-items: stretch;
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    border-color: #9e9e9e transparent transparent transparent;
    transition: 0.2s;
    transform: rotate(0deg) translateY(50%);
    right: 2px;
    top: 65%;
    transform-origin: center bottom;
  }

  &.open::after {
    transform: rotate(180deg) translateY(50%);
  }

  .select_input_container {
    flex: 1;

    input {
      padding-right: 15px;
      text-overflow: ellipsis;
      overflow: hidden;
      overflow-wrap: break-word;
    }
  }
  .select_open_button {
    position: absolute;
    right: -10px;
    cursor: pointer;
    height: 2rem;
    width: auto;
    border: none;
    outline: none;
    padding: 0 10px;
    background-color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9e9e9e;
    font-size: 8px;
    align-self: flex-end;
    transition: transform 0.3s;
    &.open {
      transform: rotate(180deg);
    }
  }
  .select_drop_down_menu_container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: auto;
    max-height: 200px;
    background-color: $WHITE;
    box-shadow: 0px 3px 8px -1px rgba(0, 0, 0, 0.75);
    z-index: 999;
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    .select_drop_down_menu_item {
      background-color: inherit;
      outline: none;
      cursor: pointer;
      text-align: left;
      padding: 5px 5px;
      overflow: hidden;
      font-size: 12px;
      border: none;
      line-height: 1.5;
      display: flex;
      width: 100%;
      transition: color 0.3s;
      //margin-bottom: 10px;
      &:hover {
        color: $DARK_GREEN;
        background-color: rgba($GREY, 0.1);
      }
    }
  }
}
