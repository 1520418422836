.input_section_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  .text_field_container {
    width: 100%;
    position: relative;
    .valid_error_message {
      color: $RED;
      position: absolute;
      top: 110%;
      left: 0;
      width: max-content;
      font-size: 11px;
      &.helper_text {
        color: #9e9e9e;
      }
    }
    .input {
      & > label {
        color: rgba($BLACK, 0.38);
        font-size: 14px;
        font-family: Montserrat, sans-serif;
        transform: translate(0, 24px) scale(1);
        &.MuiInputLabel-shrink {
          font-size: 12px;
          font-family: Montserrat, sans-serif;
          color: rgba($BLACK, 0.54);
          transform: translate(0, 1.5px) scale(1);
        }
      }
      .MuiInput-underline:before {
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        position: absolute;
        transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-bottom: 1px solid rgba($BLACK, 0.12);
        pointer-events: none;
      }

      &:hover .MuiInput-underline:before {
        border-bottom: 2px solid rgba($DARK_GREEN, 0.24);
      }
      .MuiInput-underline:after {
        border-bottom: 2px solid $DARK_GREEN;
      }
      &:focus .MuiInput-underline:after {
        transform: scaleX(1);
      }
      .MuiInput-input {
        color: $DARK_GREY;
        font-family: Montserrat, sans-serif;
        font-size: 14px;
        letter-spacing: 0;
        -webkit-text-fill-color: $DARK_GREY;
        -webkit-tap-highlight-color: transparent;
        &:-webkit-autofill,
        input:focus:-webkit-autofill {
          box-shadow: 0 0 0 100px $WHITE inset;
        }
      }
      &.valid_error {
        .MuiInput-underline:before {
          border-bottom: 1px solid rgba($RED, 0.52);
        }
      }
    }
  }

  .MuiFormHelperText-root {
    color: #9e9e9e;
    font-family: Montserrat;
    font-size: 12px;
    letter-spacing: 0;
    margin-top: 5px;
  }

  .input_password_button_container {
    position: absolute;
    right: 0;
    bottom: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    background-color: $WHITE;
    .input_password_button {
      border: none;
      outline: none;
      background-color: unset;
      cursor: pointer;
    }
  }
}
