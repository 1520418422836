.status_label {
  border-radius: 2px;
  background-color: #ffa600;
  line-height: 16px;
  color: #ffffff;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  padding: 0 4px;
  margin-left: 15px;
  &.warning {
    background-color: #ffa600;
  }
  &.danger {
    background-color: #f83535;
  }
}
