// @import '~bootstrap/scss/bootstrap';
@import-normalize;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import 'variables';
@import 'main';

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

html {
  font-size: 12px;
}
body {
  font-family: 'Montserrat', sans-serif;
  background-color: $LIGHT_GREY;
}
a {
  color: $BLACK;
  text-decoration: none;
}
ul,
li {
  list-style: none;
}

button:focus {
  outline: 0;
}

#root {
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  //max-width: 1024px;
  max-width: 1126px;
}

.left_button {
  margin-right: 20px;
}

.modal_title {
  color: $DARK_BLUE;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
}

.show-animated {
  animation: show 0.3s 1 ease;
  @keyframes show {
    from {
      opacity: 0;
      max-height: 0;
      //transform: scaleY(0.1);
    }
    to {
      opacity: 1;
      //transform: scaleY(1);
      max-height: 300px;
    }
  }
}
