.batches_container {
  .batches_title_container {
    margin-bottom: 20px;
    .batches_title {
      color: $DARK_GREY;
      font-family: Montserrat, sans-serif;
      font-size: 24px;
      font-weight: bold;
    }
  }
}

.batch_container {
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 5px 18px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  .batch_header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    .batch_title_section {
      display: flex;
      .copy_button {
        svg {
          font-size: 16px;
        }
      }
    }
    .batch_title_container {
      align-self: center;
      .batch_title {
        color: $DARK_GREY;
        font-family: Montserrat, sans-serif;
        font-size: 18px;
        font-weight: bold;
        &.button {
          background-color: unset;
          border: none;
          cursor: pointer;
          transition: 0.3s;
          &:hover {
            color: $DARK_BLUE;
          }
        }
      }
    }
    .process_date_section {
      .process_date {
        color: $GREY;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
  .batch_table_section {
    .batch_table_container {
      .deleted {
        background: repeating-linear-gradient(
          -45deg,
          rgba($GREY, 0.5) 6px,
          rgba($GREY, 0.5) 10px,
          rgba(227, 64, 64, 0.19) 10px,
          rgba(227, 64, 64, 0.19) 20px
        );
      }
      .invoice {
        flex: 0.7;
        z-index: 22;
      }
      .customer {
        flex: 1.8;
      }
      .invoice_date {
        flex: 1;
      }
      .zip_code {
        flex: 0.9;
      }
      .bill_to {
        flex: 1.7;
      }
      .revenue {
        flex: 0.6;
        text-align: right;
        justify-content: flex-end;
      }
      .tax_exemption {
        flex: 0.8;
        justify-content: center;
        text-align: center;
      }
      .tax_total {
        flex: 0.6;
        text-align: right;
        justify-content: flex-end;
        &.error_row > * {
          color: rgba($RED, 0.9);
        }
      }
      .csi_error {
        flex: 0.7;
      }
      .in_qb_cell {
        flex: 0.4;
        justify-content: center;
        text-align: center;
      }
    }
  }
}

.batches_loader_spiner {
  padding: 30px 0;
  padding-bottom: 45px;
  transition: 0.3s ease-in-out;
  &:after {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 3px solid $DARK_GREY;
    border-color: $DARK_GREY transparent $DARK_GREY transparent;
  }
}
