.qb_portal_comparison_report {
  .table_section {
    .tax_summary_report_table_scroll_container {
      width: 100%;

      .track-vertical {
        height: calc(100% - 32px);
      }
    }
    .tax_summary_report_table {
      width: 100%;
      .exempt_code {
        padding: 0 8px !important;
      }
      .tax_summary_report_table_head {
        .tax_summary_report_table_row {
          .report_cell {
            padding: 0 5px;

            &:last-child {
              padding-right: 20px;
            }
            &:first-child {
              padding-left: 20px;
            }
            &.doc_number {
              padding-right: 7px;
            }
          }
        }
      }
      .tax_summary_report_table_body {
        width: 100%;
      }
      .report_cell {
        padding: 0 5px;
        &:last-child {
          padding-right: 20px;
        }
        &:first-child {
          padding-left: 20px;
        }
        &.doc_number {
          padding-left: 7px;
        }
        &.customer_name {
          max-width: 125px;
          overflow: hidden;
          text-overflow: ellipsis;
          .customer_name_text {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &.revenue {
          .sort_button {
            text-align: center !important;
          }
          .currency_content {
            left: -6px;
          }
        }
        &.qb_cell_currency {
          padding-right: 20px;
          &:last-child {
            padding-right: 33px;
          }
        }
      }
    }
  }
  .report_spinner {
    position: absolute;
    top: 67%;
  }
}
