.checkbox_container {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 17px;
  height: 17px;
  margin-right: 5px;
  justify-content: flex-start;
  .checkbox {
    visibility: hidden;
    width: 17px;
    height: 17px;
  }
  input[type='checkbox'] + span::after {
    content: '';
    position: absolute;
    width: 17px;
    height: 17px;
    background-image: url('../../../assets/images/checkbox_e.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    top: 0;
    left: 0;
    cursor: pointer;
    transition: background-image 0.3s;
  }
  input[type='checkbox']:checked + span::after {
    background-image: url('../../../assets/images/checkbox.svg');
  }
  .label {
    white-space: nowrap;
    position: relative;
    left: 25px;
  }
  &.disabled {
    filter: grayscale(1) brightness(2);
    cursor: default;

    input[type='checkbox'] + span::after {
      cursor: default;
    }
  }
  &.header {
    input[type='checkbox'] + span::after {
      width: 17px;
      height: 17px;
      border-radius: 5px;
    }
    input[type='checkbox']:checked + span::after {
      border: 1px solid $WHITE;
      width: 15px;
      height: 15px;
    }
  }
  &.not_empty {
    input[type='checkbox'] + span::after {
      width: 17px;
      height: 17px;
      border-radius: 5px;
      background-image: none;
      background-color: $WHITE;
    }
    input[type='checkbox'] + span::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      right: 50%;
      transform: translate(-50%, -50%);
      width: 75%;
      height: 3px;
      background-color: $DARK_BLUE;
      z-index: 999;
      border-radius: 1px;
    }
  }
}
