.table_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  .table_row {
    display: flex;
    width: 100%;
    flex: 1;
    align-items: center;
    position: relative;
    // border-bottom: 1px solid rgba($LIGHT_GREY, 0.3);
    &:nth-child(odd) {
      background-color: $LIGHT_GREY_1;
    }
    &.table_header {
      background-color: $DARK_BLUE;
      .table_cell {
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 25px;
        color: $WHITE;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &.sort_button {
          outline: none;
          border: none;
          cursor: pointer;
          background-color: inherit;
          color: $WHITE;
          &:hover .triangle {
            &::after {
              visibility: visible;
            }
            &::before {
              visibility: visible;
            }
          }
          .triangle {
            position: relative;
            display: inline-flex;
            align-items: center;
            &::after,
            &::before {
              content: '';
              position: absolute;
              width: 0;
              height: 0;
              border-style: solid;
              transition: visibility 0.05s;
              border-width: 5px 4px 0 4px;
              border-color: #ffffff transparent transparent transparent;
              transform: rotate(0deg) translateY(50%);
              transform-origin: center bottom;
              visibility: hidden;
            }
            &::after {
              right: -13px;
              bottom: 36%;
              transform-origin: center bottom;
            }
            &::before {
              right: -13px;
              bottom: 64%;
              transform: rotate(180deg) translateY(50%);
            }
          }
          &.up,
          &.down {
            .triangle {
              &::after,
              &::before {
                visibility: visible;
              }
            }
          }
          &.up {
            .triangle::after {
              border-color: #ababab transparent transparent transparent;
            }
          }
          &.down {
            .triangle::before {
              border-color: #ababab transparent transparent transparent;
            }
          }
        }
      }
    }
    .table_cell {
      padding: 0 15px 0 10px;
      flex: 1;
      //display: flex;
      //align-items: center;
      line-height: 25px;
      color: $DARK_GREY;
      font-family: Montserrat, sans-serif;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      &:first-child {
        padding-left: 30px;
      }
      &:last-child {
        padding-right: 30px;
      }
      .button_in_table_cell {
        outline: none;
        border: none;
        background-color: inherit;
        color: $DARK_GREEN;
        cursor: pointer;
        &:hover {
          filter: brightness(1.3);
        }
      }
      &.editable {
        .input {
          border: none;
          border-bottom: 1px solid $DARK_GREY;
          outline: none;
          background-color: inherit;
          width: -webkit-fill-available;
          text-align: center;
          line-height: 18px;
          color: $DARK_GREY;
          font-family: Montserrat, sans-serif;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0;
          cursor: text;
          transition: color 0.3s;
          &:hover {
            border-bottom: 1px solid $DARK_GREEN;
            box-shadow: 0px 1px 0px 0px rgba($DARK_GREEN, 0.7);
          }
          &:focus {
            border-bottom: 1px solid $DARK_GREEN;
            box-shadow: 0px 1px 0px 0px rgba($DARK_GREEN, 0.7);
            color: $BLACK;
          }
          &:invalid,
          &.invalid {
            border-bottom: 1px solid $DARK_RED;
            box-shadow: 0px 1px 0px 0px rgba($DARK_RED, 0.7);
          }
        }
      }
    }
  }
  .table_footer {
    display: flex;
    margin-bottom: 10px;
    .counter {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      margin-left: 30px;
      font-size: 12px;
      font-weight: bold;
      color: $DARK_GREY;
      margin-top: 20px;
      &.size-count {
        justify-content: flex-end;
        margin-right: 30px;
      }
    }
  }
  .table_footer_spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%);
  }
}
.deleted_overlay {
  position: absolute;
  background: red;
  width: 100%;
  height: 100%;
  z-index: 2;
}
