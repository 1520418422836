.signin_connect_modal_container {
  width: 100%;
  max-width: 460px;
  border-radius: 5px;
  background-color: $WHITE;
  box-shadow: 0 5px 18px 0 rgba($BLACK, 0.2);
  padding: 42px 30px 0;
  display: flex;
  flex-direction: column;
  .signin_connect_modal_title_wrap {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .signin_connect_modal_title {
      margin-bottom: 0;
      font-size: 16px;
    }
    .MuiSvgIcon-root {
      font-size: 65px;
      margin-right: 25px;
    }
    &.Success {
      .MuiSvgIcon-root {
        color: $GREEN;
      }
    }
    &.Denied {
      .MuiSvgIcon-root {
        color: $RED;
      }
    }
  }
  &.form {
    max-width: 470px;
  }
  .signin_connect_modal_title {
    color: $DARK_BLUE;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
    &.form {
      margin-bottom: 20px;
    }
  }

  .signin_connect_modal_form_container {
    margin-bottom: 20px;
    .input_csi {
      margin-bottom: 25px;
    }
  }

  .signin_connect_modal_text {
    color: $DARK_BLUE;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 30px;
    &.first_line {
      margin-bottom: 18px;
    }
  }
  .signin_connect_modal_button {
    width: 100%;
    height: 45px;
    margin-bottom: 30px;
    &.form {
      margin-bottom: 0;
    }
  }
  .signin_connect_modal_button_link {
    margin-bottom: 30px;
    align-self: center;
    &.first {
      margin-bottom: 15px;
    }
    .signin_connect_modal_button_link_text,
    .MuiButton-label {
      opacity: 0.9;
      color: $DARK_GREEN;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    }
    .MuiButton-label {
      text-transform: none;
    }
  }
}
