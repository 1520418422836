.raw_tax_summary_text {
  color: #222222;
}
.raw_tax_modal {
  .MuiPaper-root {
    //max-width: 940px !important;
    max-width: 1042px !important;
    margin-left: 33px !important;
  }
}

.MuiDialogTitle-root {
  &.tax_summary_raw_title {
    margin-right: 60px;
    .MuiTypography-h6 {
      display: flex;
      align-items: center;
      .tax_summary_raw_download_button {
        background: none;
        outline: none;
        border: none;
        margin-left: 15px;
        cursor: pointer;
        color: $DARK_GREEN;
      }
    }
  }
}
