.filter_button {
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  background-color: unset;
  margin-right: 20px;
  cursor: pointer;
  color: #00447e;
  transition: all 0.3s;
  &:hover {
    filter: brightness(1.5);
  }
  svg {
    margin-right: 5px;
  }
  .filter_button_text {
    color: $DARK_BLUE;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
  }
}
