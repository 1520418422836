.export_confirmation_modal {
  width: 100%;
  max-width: 460px;
  padding: 30px 0;
  .message_section {
    padding: 0 30px;
    margin-bottom: 30px;
    .export_confirmation_title {
      margin-bottom: 20px;
    }
  }
  .buttons_section {
    width: 100%;
    padding: 30px 30px 0;
    display: flex;
    align-items: center;
    border-top: 1px solid $LIGHT_GREY_1;
  }
}
