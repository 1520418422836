$DARK_BLUE: #00447e;

$DARK_BLUE_1: #133959;

$DARK_GREEN: #008f98;

$DARK_RED: #d8472e;

$WHITE: #ffffff;

$LIGHT_GREY: #f8f8f8;

$LIGHT_GREY_1: #eeeeee;

$DARK_GREY: #222222;

$GREY_1: #757575;

$GREY: #858a8e;

$GREY_2: #818a8f;

$RED: #e34040;

$GREEN: #40e362;

$ORANGE: #e3aa40;

$BLACK: #000000;
