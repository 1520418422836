.logs_modal {
  min-height: 100%;
  align-items: flex-start;
  padding-top: 2.5rem;
  height: auto;
  position: absolute;
}
.logs_modal_container {
  width: 100%;
  //max-width: 940px;
  max-width: 1042px;
  height: auto;
  background-color: $WHITE;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 5px 18px 0 rgba(0, 0, 0, 0.2);
  padding: 30px 0;
  .logs_modal_title_container {
    margin-bottom: 25px;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    .new_batch_title {
      color: $DARK_BLUE;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      &.subtitle {
        font-size: 18px;
      }
    }
  }
  .logs_modal_calendar_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 35px;
    padding: 0 30px;
    .left_calendar {
      margin-right: 30px;
    }
  }
  .logs_modal_buttons_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 30px 30px 0;
    border-top: 1px solid #eeeeee;
    .logs_modal_buttons_wrap {
      width: 190px;
      &.left_button {
        margin-right: 20px;
      }
    }
  }
}
.logs_type_form_section {
  padding: 0 30px;
  .logs_type_title {
    margin-bottom: 20px;
  }
}
.logs_type_form_container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 320px;
  margin-bottom: 30px;
}
.logs_checkbox {
  margin-bottom: 25px;
  width: 32%;
  padding: 0 15px;
}
