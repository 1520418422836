.track-vertical {
  border-radius: 2px;
  background-color: #d8d8d8;
  right: 1px;
  position: absolute;
  width: 10px !important;
  height: 100%;
  top: 2px;
}
.thumb-vertical {
  width: 10px;
  border-radius: 2px;
  background-color: #004884;
}
.track-horizontal {
  border-radius: 9.5px;
  background-color: #eeeeee;
  width: 100%;
  height: 6px !important;
  bottom: 5px;
}
.thumb-horizontal {
  height: 10px !important;
  border-radius: 9.5px;
  background-color: #004884;
  bottom: calc(50% - 1px);
  //width: 20% !important;
}
