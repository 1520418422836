.tax_summary_report_container {
  //padding: 0 30px;
  .title_section {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 20px;
    .tax_summary_report_title {
      color: #222222;
      font-family: Montserrat, sans-serif;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 29px;
    }
    .tax_summary_report_date_range_label {
      color: #838a8e;
      font-family: Montserrat, sans-serif;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 15px;
      text-align: right;
    }
  }
  .table_section {
    width: 100%;
    //max-width: 964px;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 5px 18px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 30px;
    .tax_summary_report_table_scroll_container {
      //display: flex;
      //justify-content: flex-start;
      border-radius: 5px;
      overflow: auto;
      max-height: 57vh;
      .track-vertical {
        height: calc(100% - 72px);
        top: 28px;
        right: 0;
        .thumb-vertical {
          border-radius: 2px;
        }
      }
      .track-horizontal {
        width: 96%;
        bottom: 16px;
      }
    }
    .tax_summary_report_table {
      border-collapse: collapse;
      // overflow: hidden;
      .scroll_footer {
        position: sticky;
        height: 40px;
        bottom: 0;
        background-color: white;
        .footer_cell {
          position: sticky;
          background-color: white;
          height: 40px;
          bottom: -1px;
        }
      }
      .tax_summary_report_table_head {
        .tax_summary_report_table_row {
          //position: sticky;
          top: 0;
          height: 25px;
          background-color: #004682;
          .report_cell {
            background-color: #004682;
            position: sticky;
            top: 0;
            width: auto;
            padding: 0 20px;
            z-index: 99;
            &:last-child {
              padding-right: 20px;
            }
            .sort_button {
              text-align: left;
              background: none;
              outline: none;
              border: none;
              cursor: pointer;
              width: 100%;
              height: auto;
              color: #ffffff;
              font-family: Montserrat, sans-serif;
              font-size: 10px;
              font-weight: 600;
              letter-spacing: 0;
              line-height: 13px;
              &:hover .triangle {
                &::after {
                  visibility: visible;
                }
                &::before {
                  visibility: visible;
                }
              }
              .triangle {
                position: relative;
                display: inline-flex;
                align-items: center;
                &::after,
                &::before {
                  content: '';
                  position: absolute;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  transition: visibility 0.05s;
                  border-width: 5px 4px 0 4px;
                  border-color: #ffffff transparent transparent transparent;
                  transform: rotate(0deg) translateY(50%);
                  transform-origin: center bottom;
                  visibility: hidden;
                }
                &::after {
                  right: -13px;
                  bottom: 27%;
                }
                &::before {
                  right: -13px;
                  bottom: 74%;
                  transform: rotate(180deg) translateY(50%);
                }
              }
              &.up,
              &.down {
                .triangle {
                  &::after,
                  &::before {
                    visibility: visible;
                  }
                }
              }
              &.up {
                .triangle::after {
                  border-color: #ababab transparent transparent transparent;
                }
              }
              &.down {
                .triangle::before {
                  border-color: #ababab transparent transparent transparent;
                }
              }
            }
          }
        }
      }
      .tax_summary_report_table_body {
        width: 100%;
        .tax_summary_report_table_row {
          height: 25px;

          &:nth-child(odd) {
            background-color: $LIGHT_GREY_1;
          }
          &:nth-child(even) {
            background-color: $WHITE;
          }
          &.is_qb_update_needed {
            $transparent: rgba(198, 46, 12, 0.17);
            $color: rgba(0, 0, 0, 0);
            background: linear-gradient(
                -45deg,
                $transparent 49.9%,
                $color 49.9%,
                $color 60%,
                $transparent 60%
              ),
              linear-gradient(-45deg, $color 10%, $transparent 10%);
            background-size: 12.45px 12.45px;
            background-repeat: repeat;
          }
        }
      }
      .report_cell {
        width: auto;
        max-width: 300px;
        white-space: nowrap;
        padding: 0 10px 0 20px;
        color: #222222;
        font-family: Montserrat, sans-serif;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        > span {
          text-overflow: ellipsis;
        }
        &.align_center {
          text-align: center;
        }
        &.currency {
          text-align: right;
          // position: relative;
          // right: 12px;
          .currency_content {
            padding-right: 12px;
          }
        }
        &.revenue {
          .currency_content {
            position: relative;
            left: -12px;
          }
        }
        .batch_id_cell_container {
          display: flex;
          justify-content: space-between;
        }
        .copy_button {
          padding: 0;
          margin-left: -5px;
          font-size: 1rem;
          .MuiSvgIcon-root {
            width: 0.8em;
            height: 0.8em;
          }
        }
        .button_cell {
          background: none;
          outline: none;
          border: none;
          cursor: pointer;
          color: #008f98;
          font-family: Montserrat, sans-serif;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 15px;
          &:hover {
            filter: brightness(1.3);
          }
        }
      }
    }
  }
  .sum_total_tax_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    &.qb_report {
      justify-content: flex-end;
    }
    .sum_total_tax_message {
      font-size: 12px;
      font-weight: 600;
      color: #222222;
      a {
        color: #004682;
      }
    }
    .sum_total_tax_title {
      font-size: 12px;
      font-weight: bold;
      color: #222222;
    }
  }
}
