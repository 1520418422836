.radio_button_label_container {
  position: relative;
  .tool_tip_container {
    position: absolute;
    right: 35%;
    top: 8px;
  }
}
.radio_button_container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .custom_radio_button {
    display: none;
    & + span {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 17px;
      width: 17px;
      border: 1px solid $DARK_BLUE;
      border-radius: 50%;
      background-color: $WHITE;
      transition: 0.3s;
    }
    &:checked + span {
      &::after {
        content: '';
        height: 13px;
        width: 13px;
        border-radius: 8.5px;
        background-color: $DARK_BLUE;
        transition: 0.3s;
      }
    }
  }

  .MuiFormControlLabel-label {
    color: rgba(0, 0, 0, 0.54);
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 24px;
  }
}

.selected {
  background-color: $LIGHT_GREY_1;
}
