.company_batches_container {
  padding: 30px 42px 0;
  flex: unset;
  .batches_search_section {
    order: 2;
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    .search_container {
      flex: 0.48126;
      height: 100%;
      display: flex;
      align-items: flex-end;
      .batches_page_search_input_container {
        flex: 1;
      }
    }
    .batches_filter_group {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .new_batches_btn {
        width: 140px;
      }
    }
  }
  .company_batches_header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 30px;
    order: 1;
    .company_batches_button_back {
      position: absolute;
      left: 0;
      .company_batches_button {
        color: $DARK_BLUE;
        font-weight: 600;
        .company_batches_button_name {
          display: flex;
          align-items: center;
          > svg {
            margin-right: 10px;
          }
        }
      }
    }
    .company_batches_title {
      h2 {
        color: #222222;
      }
    }
  }
  .batches_section {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    order: 3;
  }
}
