.report_nav_menu_container {
  display: flex;
  height: 50px;
  ///padding: 0 30px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $LIGHT_GREY_1;
  margin-bottom: 20px;
  .header_menu_item {
    display: flex;
    align-items: center;
    margin-right: 30px;
    color: $DARK_BLUE;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
    transition: 0.3s;
    height: 100%;
    &:hover {
      color: $DARK_GREEN;
    }
    &.active {
      color: $DARK_GREEN;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        border-bottom: 2px solid $DARK_GREEN;
        width: 100%;
      }
    }
  }
}
