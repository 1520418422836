.field_container {
  display: flex;
  flex-direction: column;
  &.right_align {
    align-items: flex-end;
  }
  .field_title {
    color: #757575;
    font-family: Montserrat, sans-serif;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 13px;
  }
  .field_text {
    color: #222222;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    min-height: 15px;
  }
  .field_link {
    .field_text {
      color: #078f98;
    }
  }
}
