.profile_modal {
  align-items: flex-start;
  padding-top: 5rem;
  height: auto;
  position: absolute;
}
.user_profile_conatainer {
  width: 100%;
  max-width: 460px;
  background-color: $WHITE;
  border-radius: 5px;
  box-shadow: 0 5px 18px 0 rgba($BLACK, 0.2);
  padding: 30px 0;
  position: relative;
  .user_profile_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 25px;
    border-bottom: 1px solid rgba($BLACK, 0.12);
    margin-bottom: 20px;
    .user_profile_header__title {
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      color: $DARK_BLUE;
    }
  }
  .user_profile_form_section {
    display: flex;
    flex-direction: column;
    .order_1 {
      order: 1;
    }
    .order_2 {
      order: 2;
    }
    .order_3 {
      order: 3;
    }
    .order_4 {
      order: 4;
      .button_wrap {
        width: 100%;
      }
      .make_admin_switch {
        width: 100%;
        margin-bottom: 15px;
        color: #222222;

        .MuiFormControlLabel-label {
          font-size: 14px;
          font-family: Montserrat, sans-serif;
          font-weight: 600;
        }
      }
    }
    .user_profile_form {
      display: contents;
    }
    .user_profile_form__sections {
      padding: 0 30px 5px 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid rgba($BLACK, 0.12);
      margin-bottom: 20px;
      .user_profile_form__title {
        color: $DARK_BLUE;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        margin-bottom: 3px;
        align-self: flex-start;
        &.account {
          margin-bottom: 20px;
        }
      }
      .user_profile_form__inputs_row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        &.buttons {
          flex-direction: column;
          .user_profile_form__inputs_row_buttons_container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          }
          .profile_info_box_container {
            margin-bottom: 15px;
          }
        }
        .margin_left {
          margin-right: 25px;
        }
      }
      .csi_key_title {
        color: #133959;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        font-style: italic;
      }
      .copy_button {
        color: $DARK_GREEN;
      }
      &.user_profile_form__section_account {
        margin-bottom: 30px;
      }
      &.user_profile_form__section_buttons {
        border: none;
        margin-bottom: 0;
        padding-bottom: 0;
        .user_profile_form_buttons {
          margin-bottom: 0;
          height: 45px;
          width: 100%;
        }
      }
    }

    .user_profile_form__inputs_container {
      margin-bottom: 25px;
      width: 100%;
    }
    .user_profile_form__inputs {
      width: 100%;
    }
    .user_profile_form_buttons_container {
      margin-bottom: 20px;
      width: 100%;
    }
    .user_profile_form_buttons {
      width: 100%;
      height: 45px;
    }
  }
}
