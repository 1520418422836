.filter_type_form_section {
  padding: 0 30px;
  margin-bottom: 30px;
  .filter_type_title {
    margin-bottom: 20px;
  }
}
.invoice_filter_type_form_container {
  display: flex;
  justify-content: flex-start;
  form {
    display: contents;
  }
}
.invoice_filter_checkbox {
  padding: 0 15px;
  width: 25%;
}
