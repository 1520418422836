.companies_list_container {
  display: flex;
  width: 100%;
  flex-direction: column;
  .company_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    border-bottom: 1px solid #eeeeee;
    .comapny_name_section {
      .comapny_name_button {
        display: flex;
        align-items: center;
        border: none;
        outline: none;
        background-color: unset;
        color: $DARK_BLUE;
        font-family: Montserrat, sans-serif;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        cursor: pointer;
        .arrow_button_icon {
          margin-right: 15px;
          font-size: 32px;
          transition: 0.3s;
        }
        &.open {
          .arrow_button_icon {
            transform: rotate(180deg);
            transition: 0.3s;
          }
        }
        &:hover,
        &:focus {
          filter: brightness(1.4);
        }
      }
    }
    .comapny_id_section {
      color: #838a8e;
      font-family: Montserrat, sans-serif;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
    }
  }
}
