.users_table_container {
  display: flex;
  width: 100%;
  flex-direction: column;
  .users_table_title_container {
    margin-bottom: 20px;
  }
  .users_table_title {
    color: #222222;
    font-family: Montserrat, sans-serif;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 29px;
  }
  .users_table {
    border-radius: 5px;
    box-shadow: 0 5px 18px 0 rgba(0, 0, 0, 0.2);
    //overflow: hidden;
    .users_body_row {
      padding-left: 5px;
    }
    .name {
      flex: 1.6;
      .name_users {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        .name_text {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .company_name {
      flex: 1.2;
    }
    .company_id,
    .csi_access_token {
      flex: 1;
      .copy_text_container {
        display: flex;
        .copy_button {
          padding: 0 0 0 5px;
          font-size: 1rem;
          .MuiSvgIcon-root {
            width: 0.8em;
            height: 0.8em;
          }
        }
      }
    }
    .role {
      flex: 0.6;
    }
    .last_login {
      flex: 0.7;
    }
    .user_email {
      flex: 1.9;
    }
    .actions {
      flex: 0.3;
      overflow: visible;
    }
    .inactive {
      color: rgba($DARK_GREY, 0.5);
    }
    .users_table_header {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      padding-left: 5px;
      .actions {
        overflow: hidden;
      }
    }
  }
}

.users_menu_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .users_menu_button {
    width: 23px;
    height: 23px;
    background-color: unset;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.1s;
    &:hover {
      .users_menu_button_icon {
        transform: scale(1.1);
        transition: all 0.1s;
      }
    }
    &:focus {
      border: 1px solid rgba($DARK_BLUE, 0.7);
    }
    &.active {
      background-color: $DARK_BLUE;
      .users_menu_button_icon {
        color: #ffffff;
      }
    }
    .users_menu_button_icon {
      color: $DARK_BLUE;
      width: inherit;
      // height: 18px;
      font-size: 24px;
      transform: scale(1.05);
    }
  }
  .users_menu_wrap {
    position: absolute;
    z-index: 2;
    right: -25px;
    top: 38px;
    display: flex;
    flex-direction: column;
    width: 220px;
    background-color: $WHITE;
    filter: drop-shadow(0 0 5px rgba($BLACK, 0.2));
    padding: 25px 0;
    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 15px 10px;
      border-color: transparent transparent $WHITE transparent;
      top: -15px;
      right: 30px;
    }
    .menu_item {
      margin-bottom: 14px;
      &:last-child {
        margin-bottom: unset;
      }
      .menu_item_button {
        width: 100%;
        height: 35px;
        text-align: left;
        border: none;
        outline: none;
        background-color: unset;
        cursor: pointer;
        color: #00447e;
        font-family: Montserrat, sans-serif;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 18px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        &:hover,
        &:focus {
          background-color: $LIGHT_GREY;
          transition: 0.3s;
        }
        .menu_item_button_icon {
          margin-right: 10px;
          font-size: 26px;
        }
        .menu_item_delete_icon {
          margin-right: 10px;
          font-size: 26px;
          color: $RED;
        }
        &.red-item {
          color: $RED;
        }
      }
    }
  }
}
