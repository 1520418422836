.styled_calendar {
  &.MuiFormControl-root {
    width: 100%;
    max-width: 190px;
    .MuiFormLabel-root {
      color: rgba($BLACK, 0.4);
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      letter-spacing: 0;
      &.MuiInputLabel-shrink {
        color: rgba($BLACK, 0.54);
      }
    }
    .MuiIconButton-root {
      color: $DARK_GREEN;
      position: relative;
      right: -12px;
    }
    .MuiInput-underline:before {
      border-bottom: 1px solid rgba($BLACK, 0.12);
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 2px solid rgba($BLACK, 0.47);
    }
    .MuiInput-underline:after {
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      position: absolute;
      transform: scaleX(0);
      transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      border-bottom: 2px solid $DARK_GREEN;
      pointer-events: none;
    }
    .MuiInputBase-input {
      color: $DARK_GREY;
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      letter-spacing: 0;
    }
    .MuiFormHelperText-root {
      position: absolute;
      bottom: -15px;
    }
  }
}
