.tool_tip_container {
  .MuiSvgIcon-root {
    color: $GREY;
    font-size: 1.601rem;
    &:hover {
      color: $DARK_BLUE;
    }
  }
}

.MuiTooltip-popperArrow {
  .MuiTooltip-tooltip {
    background-color: $DARK_BLUE;
    box-shadow: 0 5px 18px 0 rgba($BLACK, 0.2);
    color: $WHITE;
    font-family: Montserrat, sans-serif;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
    padding: 10px 15px;
  }
  .MuiTooltip-arrow {
    color: $DARK_BLUE;
  }
}
