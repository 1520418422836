.search_input_container {
  display: flex;
  align-items: center;
  //padding: 0 30px 0 20px;
  .search_input_wrap {
    border-bottom: 1px solid rgba($BLACK, 0.12);
    display: flex;
    align-items: center;
    flex: 1;
    position: relative;
    transition: 0.3s;
    &.focus {
      &:after {
        position: absolute;
        content: '';
        bottom: -1px;
        height: 2px;
        width: 100%;
        left: 0;
        background-color: rgba($DARK_BLUE, 0.7);
        transition: all 0.3s ease-out;
      }
    }
    &:after {
      position: absolute;
      content: '';
      bottom: -1px;
      height: 2px;
      width: 0;
      left: 50%;
      background-color: rgba($DARK_BLUE, 0.7);
      transition: all 0.3s ease-out;
    }
    &:hover {
      background-color: rgba($DARK_BLUE, 0.04);
      &:after {
        width: 100%;
        left: 0;
        transition: all 0.3s ease-in;
      }
    }
    .search_input_icon_container {
      position: absolute;
      left: -2px;
      z-index: 3;
      top: 0;
    }
    .cancel_button {
      visibility: hidden;
    }
    &.not_empty {
      .cancel_button {
        position: absolute;
        right: 5px;
        z-index: 2;
        bottom: 0;
        background: none;
        outline: none;
        border: none;
        color: rgb(153, 153, 153);
        cursor: pointer;
        visibility: visible;
        transition: 0.3s;
        &:hover {
          color: $DARK_GREY;
        }
      }
    }

    .search_input {
      flex: 1;
      border: none;
      background-color: unset;
      outline: none;
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px;
      padding-left: 25px;
      z-index: 2;
      padding-right: 30px;
      &::placeholder {
        color: rgba($BLACK, 0.38);
        opacity: 1;
        font-family: Montserrat, sans-serif;
        font-size: 14px;
        letter-spacing: 0;
      }
    }
  }
}
