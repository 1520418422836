.map_products_container {
  display: flex;
  flex-direction: column;
  .map_products_title_container {
    margin-bottom: 20px;
    padding: 0 30px;
    .info_box_unmaped_products {
      width: 100%;
      margin-top: 5px;
    }
  }
  .map_products_tables_container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 30px;
    .map_products_table {
      flex: 1;
      display: flex;
      flex-direction: column;
      &:first-child {
        margin-right: 1px;
      }
      .header_title {
        flex: 1;
        background-color: $DARK_BLUE;
        color: $WHITE;
        font-family: Montserrat, sans-serif;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 25px;
        padding: 0 30px;
        margin-bottom: 8px;
        min-height: 25px;
        display: flex;
        align-items: center;
        .product_form_checkbox_all {
          span {
            margin-left: 27px;
          }
        }
      }
      .map_products_search_container {
        margin-bottom: 10px;
        &.left {
          padding-left: 30px;
          margin-right: 10px;
        }
        &.right {
          padding-right: 20px;
          padding-left: 8px;
        }
      }
      .products_form {
        //padding-left: 30px;
        //padding-top: 10px;
        .product_radio_button_line {
          display: flex;
          align-items: center;

          width: 100%;
          margin-left: -9px;
          margin-right: 1px;
          padding-left: 40px;
          min-height: 35px;
          height: auto;
          &:hover {
            background-color: $LIGHT_GREY_1;
          }

          .product_form_checkbox {
            padding-left: 9px;
            width: 100%;
            color: #222;
            font-family: Montserrat, sans-serif;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 15px;
          }
        }
      }
    }
  }
  .map_product_button_group {
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-right: 20px;
    .button_wrapp {
      width: auto;
      &.first {
        margin-right: 20px;
      }
    }
    .map_product_button {
      width: 390px;

      &.first {
        width: 220px;
      }
    }
  }
  &.create_batch {
    .products_radio_group {
      .MuiFormControlLabel-label {
        color: #ff0027;
        font-family: Montserrat, sans-serif;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 15px;
      }
    }
  }
}

.products_radio_group,
.csi_products_radio_group {
  .MuiFormControlLabel-label {
    color: $DARK_GREY;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
  }
}

.csi_products_services_list {
  padding-left: 8px;
  .csi_products_services_list_button {
    border: none;
    background-color: unset;
    outline: none;
    color: $DARK_GREY;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
    cursor: pointer;
    position: relative;
    display: inline-flex;
    align-items: center;
    padding-left: 15px;
    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 5px 0 5px;
      border-color: #004687 transparent transparent transparent;
      transition: 0.2s;
      transform: rotate(0deg) translateY(50%);
      left: 0;
      bottom: 50%;
      transform-origin: center bottom;
    }
    &.up {
      &::after {
        transform: rotate(180deg) translateY(50%);
      }
    }
  }
  .csi_products_list_wrap {
    display: flex;
    flex-direction: column;
    padding-left: 17px;
    .csi_product_label_container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex: 1;
      .code {
        white-space: pre;
        //align-self: center;
      }
    }
    .csi_product_service {
      width: 100%;
      min-height: 35px;
      height: auto;
      margin-bottom: 5px;
      margin-left: -25px;
      margin-right: 1px;
      padding-left: 15px;
      padding-right: 15px;
      &:hover {
        background-color: $LIGHT_GREY_1;
      }
    }
  }
}
