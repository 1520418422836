.tax_summary_modal {
  .tax_summary_info_box {
    padding: 0 30px;
    margin-bottom: 30px;
  }
  .tax_summary_modal_title_section {
    padding: 0 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    .tax_summary_raw_button_container {
      display: flex;
      justify-content: flex-end;
      margin-right: 60px;
      .button_wrap {
        &:first-child {
          margin-right: 30px;
        }
      }
      .tax_summary_raw_button {
        color: $DARK_GREEN;
      }
    }
  }

  .text_fields_section {
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid $LIGHT_GREY_1;
    margin-bottom: 40px;
    .text_fields_item {
      margin-right: 0;
    }
  }
  .tax_summary_modal_total_section {
    display: flex;
    //flex-direction: column;
    justify-content: flex-end;
    margin: 0 30px;

    .total {
      & > span:last-child {
        color: #222222;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 29px;
        align-self: flex-end;
      }
      &.smaller {
        margin-bottom: 15px;
        & > span:last-child {
          font-size: 18px;
        }
      }
    }
  }
  .invoice_details_tax_summary_table_container {
    // border-bottom: 1px solid #eeeeee;
  }
  .invoice_details_tax_summary_table_section {
    margin-bottom: 10px;
    display: flex;
    .invoice_details_tax_summary_table {
      .tax_description {
        flex: 6;
      }
      .tax_authority {
        flex: 2;
      }
      .tax_amount {
        flex: 1.2;
        justify-content: flex-end;
        margin-right: 5px;
        &.right {
          text-align: right;
        }
      }
      .billable {
        flex: 0.5;
      }
    }
  }
  .tax_summary_buttons_container {
    border-top: none;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .tax_summary_buttons_item {
      flex: 1;
      &.buttons {
        padding-left: 30px;
        display: flex;
      }
    }
  }
}
