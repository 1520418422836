.new_batch {
  // height: auto;
  // min-height: 100vh;
  min-height: 100%;
  align-items: flex-start;
  padding-top: 7rem;
  height: auto;
  position: absolute;
}

.new_batch_modal_container {
  width: 100%;
  //max-width: 940px;
  max-width: 1042px;
  height: auto;
  background-color: $WHITE;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 5px 18px 0 rgba(0, 0, 0, 0.2);
  padding: 30px 0;
  //margin-top: -15%;
  .zero_tax_form_section {
    padding: 0 30px;
  }
  .zero_tax_form_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .zero_tax_checkbox {
    width: 100%;
    padding: 0 15px;
  }
  .new_batch_modal_title_container {
    margin-bottom: 25px;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    .new_batch_title {
      color: $DARK_BLUE;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      &.subtitle {
        font-size: 18px;
      }
    }
    .info_box_new_batch {
      height: 45px;
      width: 100%;
      margin-top: 10px;
    }
    .info_box_new_batch_date_range {
      width: 100%;
      margin-top: 10px;
      p {
        line-height: 2;
      }
    }
  }

  .new_batch_modal_calendar_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 25px;
    padding: 0 30px;
    .left_calendar {
      margin-right: 30px;
    }
  }
  .transaction_type_radiogroup_container {
    padding: 0 30px;
    margin-bottom: 15px;
    .transaction_type_radiogroup_title {
      font-family: Montserrat, sans-serif;
      font-size: 11px;
      letter-spacing: 0;
      color: rgba(0, 0, 0, 0.54);
      margin-bottom: 10px;
    }
    .transaction_type_radiobutton_container {
      flex-direction: row;
    }
  }
  .new_batch_modal_buttons_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 30px 30px 0;
    border-top: 1px solid #eeeeee;
    .new_batch_modal_buttons_wrap {
      width: 190px;
      &.left_button {
        margin-right: 20px;
      }
    }

    .confirm_create_batch_checkbox {
      width: auto;
      margin-left: 30px;
      padding-left: 10px;
    }
  }
  .new_batch_modal_availeble_invoices_container {
    margin-bottom: 30px;
    .new_batch_modal_availeble_invoices_table_container {
      .include {
        flex: 0.8;
      }
      .emailed {
        flex: 0.7;
      }
      .taxes_exist {
        flex: 1;
      }
      .tax_exemption {
        flex: 0.6;
      }
      .invoice {
        flex: 1;
        color: #008f98;
      }
      .type {
        flex: 0.7;
      }
      .customer {
        flex: 1.8;
      }
      .cust_type {
        flex: 1;
      }
      .invoice_date {
        flex: 1.2;
      }
      .zip_code {
        flex: 1.2;
        text-align: left;
        .input {
          text-align: left;
          margin-left: -5px;
          padding-left: 5px;
        }
      }
      .bill_to {
        flex: 1.6;
      }
      .revenue {
        flex: 1.3;
        display: inline;
        text-align: right;
      }
      .header {
        .revenue {
          justify-content: flex-end;
        }
      }
      .without_quantity {
        $transparent: rgba(198, 46, 12, 0.17);
        $color: rgba(0, 0, 0, 0);
        background: linear-gradient(
            -45deg,
            $transparent 49.9%,
            $color 49.9%,
            $color 60%,
            $transparent 60%
          ),
          linear-gradient(-45deg, $color 10%, $transparent 10%);
        background-size: 13.95px 13.95px;
        background-repeat: repeat;
        // span,
        // button,
        // form > input {
        //   color: $GREY !important;
        // }
      }
      .deleted {
        $transparent: rgba(65, 64, 63, 0.17);
        $color: rgba(0, 0, 0, 0);
        background: linear-gradient(
            -45deg,
            $transparent 49.9%,
            $color 49.9%,
            $color 60%,
            $transparent 60%
          ),
          linear-gradient(-45deg, $color 10%, $transparent 10%);
        background-size: 13.95px 13.95px;
        background-repeat: repeat;
        span,
        button,
        form > input {
          color: $GREY !important;
        }
        //filter: brightness(0.5);
      }
    }
    &.unmapped_products {
      //height: 300px;
      //background-color: #f1f4f9;
    }
    .unmaped_product_title_container {
      margin-bottom: 10px;
      margin-top: 10px;
    }
    .infobox_section {
      //padding: 0 30px;
      display: flex;
    }
  }
}

.select_tax_exempt {
  display: flex;
  align-items: center;

  &.cust_type_select {
    width: 60px;
  }
  .MuiInputBase-input {
    color: #222222;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;

    &.MuiSelect-select {
      padding-right: 10px;
      padding-left: 10px;
    }
    &:focus {
      background-color: unset;
    }
  }
  .MuiSelect-icon {
    color: #222222;
    top: unset;
  }

  &.MuiInput-underline {
    &:before {
      left: 0;
      right: 0;
      bottom: 4px;
      content: '\00a0';
      position: absolute;
      transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border-bottom: 1px solid #222222;
      pointer-events: none;
    }
    &:after {
      left: 0;
      right: 0;
      bottom: 3px;
      content: '';
      position: absolute;
      transform: scaleX(0);
      transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      border-bottom: 2px solid #008f98;
      pointer-events: none;
    }
    &:hover:not(.Mui-disabled):before {
      border-bottom: 1px solid #008f98;
    }
  }
}

.exemptions_type_modal_content_container {
  .modal_title_container {
    margin-bottom: 25px;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    .new_batch_title {
      color: $DARK_BLUE;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      &.subtitle {
        font-size: 18px;
      }
    }
  }
  .logs_modal_buttons_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 30px 0;
    border-top: 1px solid #eeeeee;
    width: 100%;
    > div {
      display: flex;
    }
    .buttons_container {
      display: flex;
      align-items: center;
    }
    .logs_modal_buttons_wrap {
      width: 190px;
      margin-right: 20px;
    }
    .confirm_checkbox {
      width: auto;
      padding: 0 15px;
      width: 235px;
    }
  }
  .exemptions_type_form_section {
    padding: 0 30px;
    height: 320px;
    overflow: auto;
    .exemptions_type_form_container {
      display: flex;
      flex-wrap: wrap;
      //flex-direction: column;
      //width: 100%;
      margin-bottom: 30px;
      //height: auto;
    }
    .exemptions_type_checkbox {
      margin-bottom: 25px;
      width: 32%;
      padding: 0 15px;
    }
  }
}

.select_item_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
