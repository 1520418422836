.processed_batch_modal {
  .processed_batch_invoices_container {
    margin-bottom: 0;
  }
  .new_batch_modal_availeble_invoices_table_container {
    margin-bottom: 30px;
    &.processed_batches {
      .processed_batch_summary_table_row,
      .table_header {
        .include {
          flex: 0.8;
        }
        .emailed {
          flex: 0.7;
        }
        .taxes_exist {
          flex: 1;
        }
        .tax_exemption {
          flex: 0.6;
        }
        .invoice {
          flex: 1.3;
        }
        .customer {
          flex: 2.1;
        }
        .cust_type {
          flex: 0.7;
        }
        .invoice_date {
          flex: 1.2;
        }
        .zip_code {
          flex: 1.4;
        }
        .revenue {
          padding-right: 15px;
          display: flex;
          text-align: right;
          justify-content: flex-end;
          flex: 1.2;
        }
        .tax_total {
          flex: 1.4;
          text-align: right;
          justify-content: flex-end;
          &.error > * {
            color: rgba($RED, 0.9);
          }
        }
      }
      .deleted {
        $transparent: rgba(65, 64, 63, 0.17);
        $color: rgba(0, 0, 0, 0);
        background: linear-gradient(
            -45deg,
            $transparent 49.9%,
            $color 49.9%,
            $color 60%,
            $transparent 60%
          ),
          linear-gradient(-45deg, $color 10%, $transparent 10%);
        background-size: 12.45px 12.45px;
        background-repeat: repeat;
      }
      .is_qb_update_neded {
        $transparent: rgba(198, 46, 12, 0.17);
        $color: rgba(0, 0, 0, 0);
        background: linear-gradient(
            -45deg,
            $transparent 49.9%,
            $color 49.9%,
            $color 60%,
            $transparent 60%
          ),
          linear-gradient(-45deg, $color 10%, $transparent 10%);
        background-size: 12.45px 12.45px;
        background-repeat: repeat;
      }
    }
  }

  .processed_batch_modal_buttons_container {
    display: flex;
    justify-content: flex-start;
    padding: 0 30px;
    .processed_batch_modal_buttons_wrap {
      &.left_button {
        .processed_batch_modal_buttons {
          width: 260px;
        }
      }
    }
  }
}
