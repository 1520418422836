.mapped_products_container {
  border-bottom: none !important;
  padding-bottom: unset !important;
  content-visibility: auto;
  .mapped_products_title_container {
    padding: 0 30px;
    margin-bottom: 20px;
    .mapped_products_title {
      margin-bottom: 20px;
    }
    .mapped_products_search_container {
      width: 300px;
    }
  }
  .mapped_products_table_container {
    margin-bottom: 30px;
    .mapped_product_table_row {
      height: 45px;
    }
    .select_cell {
      flex: 0.02;
    }
    .products_cell {
      flex: 3.05;
    }
    .tax_code_cell {
      flex: 3;
      white-space: normal;
      line-height: 15px;
    }
  }
  .unmap_product_button_group {
    display: flex;
    justify-content: flex-start;
    padding-left: 30px;
    padding-right: 20px;
    .button_wrapp {
      width: auto;
      &.first {
        margin-right: 20px;
        .unmap_product_button {
          width: 220px;
        }
      }
    }
    .unmap_product_button {
      width: 190px;
    }
  }
}
