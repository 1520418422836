.logo_container {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  //padding: 0.3em;
  .logo {
    width: 100%;
    height: auto;
  }
}
