.processing_invoices_modal_container {
  align-items: center;
  .processing_invoices_modal_wrap {
    width: 100%;
    max-width: 460px;
    padding: 30px 0;
  }
  .processing_invoices_modal_title_section {
    padding: 0 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    position: relative;
    .progress_container {
      position: absolute;
      left: 30px;
    }
    // .processing_invoices_modal_spinner {
    //   margin-right: 15px;
    // }
  }
  .processing_invoices_modal_text_area_section {
    padding: 0 30px 30px;
    border-bottom: 1px solid $LIGHT_GREY_1;
    margin-bottom: 30px;
    .info_box_processing_invoices {
      margin-bottom: 30px;
    }
    .first_paragraph {
      margin-bottom: 15px;
    }
  }
  .processing_invoices_modal_button_section {
    padding: 0 30px;
    .processing_invoices_modal_button {
      width: 100%;
    }
  }
}
