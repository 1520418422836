.invoices_container {
  .infoboxes_container {
    margin-bottom: 20px;
  }
  .qb_infobox_container {
    margin-bottom: 20px;
  }
  .invoices_title_container {
    margin-bottom: 20px;
    .invoices_title {
      color: $DARK_GREY;
      font-family: Montserrat, sans-serif;
      font-size: 24px;
      font-weight: bold;
    }
  }
  .invoices_table_section {
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 5px 18px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    overflow: hidden;
    .table_footer {
      height: 20px;
    }
    .table_header {
    }
    .invoice__table_row {
      &.qb_status {
        $transparent: rgba(198, 46, 12, 0.17);
        $color: rgba(0, 0, 0, 0);
        background: linear-gradient(
            -45deg,
            $transparent 49.9%,
            $color 49.9%,
            $color 60%,
            $transparent 60%
          ),
          linear-gradient(-45deg, $color 10%, $transparent 10%);
        background-size: 12.45px 12.45px;
        background-repeat: repeat;
      }
      &.deleted {
        $transparent: rgba(65, 64, 63, 0.17);
        $color: rgba(0, 0, 0, 0);
        background: linear-gradient(
            -45deg,
            $transparent 49.9%,
            $color 49.9%,
            $color 60%,
            $transparent 60%
          ),
          linear-gradient(-45deg, $color 10%, $transparent 10%);
        background-size: 12.45px 12.45px;
        background-repeat: repeat;
      }
    }

    .process_date {
      flex: 1.3;
    }
    .batch_id {
      flex: 0.8;
    }
    .invoice_num {
      flex: 1;
    }
    .invoice_type {
      flex: 0.7;
    }
    .customer {
      flex: 2;
    }
    .cust_type {
      flex: 0.9;
    }
    .invoice_date {
      flex: 1.25;
    }
    .postal_code {
      flex: 1.35;
    }
    .exempt_code {
      flex: 0.7;
      justify-content: center;
      text-align: center;
    }
    .revenue,
    .tax_total {
      flex: 1.45;
      text-align: right;
      justify-content: flex-end !important;
      &.error_row > * {
        color: rgba($RED, 0.9);
      }
    }
  }
}

.invoices_loader_spiner {
  padding-top: 20px;
  //padding-bottom: 45px;
  transition: 0.3s ease-in-out;
  &:after {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 3px solid $DARK_GREY;
    border-color: $DARK_GREY transparent $DARK_GREY transparent;
  }
}
