.login_container {
  height: 100vh;
  justify-content: flex-start;
  padding-top: 50px;
  .login_logo_container {
    margin-bottom: 30px;
    padding: 5px;
    // width: 184px;
    // height: 127px;
    // background-image: url('../../assets/images/logo.png');
    // background-repeat: no-repeat;
    // background-size: contain;
    // background-position: center center;
    .login_logo {
      width: 184px;
      height: auto;
    }
  }
  .login_form_container {
    display: flex;
    flex-direction: column;
    width: 45%;
    max-width: 460px;
    min-width: 360px;
    border-radius: 5px;
    box-shadow: 0 5px 18px 0 rgba($BLACK, 0.2);
    padding: 2.5rem;
    background-color: $WHITE;
    .login_form__title {
      color: $DARK_BLUE;
      margin-bottom: 20px;
    }
    .login_form__infobox_container {
      height: 45px;
      // margin-bottom: 15px;
      animation: shown_info_box 0.3s 1 linear;
      &.closing {
        height: 0;
        margin-bottom: 0;
        animation: closing_info_box 0.3s 1 linear;
        .login_form__infobox_text {
          opacity: 0;
        }
      }
      .login_form__infobox_text {
        color: $RED;
      }
      @keyframes shown_info_box {
        from {
          height: 0;
          opacity: 0;
          // margin-bottom: 0;
        }
        to {
          height: 45px;
          opacity: 1;
          // margin-bottom: 15px;
        }
      }
      @keyframes closing_info_box {
        from {
          height: 45px;
          opacity: 1;
          //margin-bottom: 15px;
        }
        to {
          height: 0;
          opacity: 0;
          //margin-bottom: 0;
        }
      }
    }

    .login_form_access_acc {
      color: $DARK_GREEN;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
      align-self: center;
      .MuiButton-label {
        text-transform: none;
      }
      &:hover,
      &:focus {
        filter: brightness(1.2);
        transition: 0.3s;
      }
    }
  }
}

.login_form__inputs_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 30px;
  .login_form__input_email_container {
    margin-bottom: 10px;
    margin-top: 15px;
    &.password {
      margin-bottom: 30px;
    }
  }
  .login_form__input {
    width: 100%;
  }
  .button_wrap {
    position: relative;
    display: flex;
    align-items: center;
    .login_form_sign_button {
      height: 45px;
      width: 100%;
    }
    .spinner {
      position: absolute;
      right: 10px;
      align-self: center;
    }
  }
}
