.loading_screen_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90vh;
  width: 100%;
  &.center {
    height: 100%;
    max-height: 90vh;
  }
  .loading_screen_spinner {
    &:after {
      border-color: $DARK_GREEN transparent $DARK_GREEN transparent;
    }
  }
}
