.settings_modal {
  align-items: flex-start;
  padding-top: 7rem;
  height: auto;
  position: absolute;
  .settings_conatainer {
    width: 100%;
    //max-width: 940px;
    max-width: 1042px;
    padding: 30px 0;
    .settings_header {
      // margin-bottom: 16px;
      padding: 0 30px 20px;
      border-bottom: 1px solid $LIGHT_GREY_1;
    }
    .piu_section,
    .map_products_section,
    .mapped_products_section {
      padding: 20px 30px 30px 30px;
      border-bottom: 1px solid $LIGHT_GREY_1;
    }
    .map_products_section {
      padding-left: 0;
      padding-right: 0;
    }
    .mapped_products_section {
      padding-bottom: 0;
      border-bottom: none;
    }

    .piu_section {
      .piu_forms_container {
        display: flex;
        .piu_rate_section {
          margin-right: 90px;
        }
      }
      .piu_provider_type_sections {
        .MuiFormControl-root {
          .piu_input {
            width: 155px;
          }
          .MuiInput-underline:before {
            left: 0;
            right: 0;
            bottom: 0;
            content: '';
            position: absolute;
            transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1)
              0ms;
            border-bottom: 1px solid rgba($BLACK, 0.12);
            pointer-events: none;
          }

          &:hover .MuiInput-underline:before {
            border-bottom: 2px solid rgba($DARK_GREEN, 0.24);
          }
          .MuiInput-underline:after {
            border-bottom: 2px solid $DARK_GREEN;
          }
          &:focus .MuiInput-underline:after {
            transform: scaleX(1);
          }
          .MuiInput-input {
            color: $DARK_GREY;
            font-family: Montserrat, sans-serif;
            font-size: 14px;
            letter-spacing: 0;
            -webkit-text-fill-color: $DARK_GREY;
            -webkit-tap-highlight-color: transparent;
            &:-webkit-autofill,
            input:focus:-webkit-autofill {
              box-shadow: 0 0 0 100px $WHITE inset;
            }
          }
        }
      }
      .sections_title_container {
        display: flex;
        align-items: center;
        margin-bottom: 22px;
        //height: 45px;
        .sections_title {
          margin-right: 20px;
        }
        .piu_infobox_container {
          width: 100%;
          margin-left: 30px;
          &.provider_type {
            margin-left: unset;
            width: 230px;
          }
        }
      }
      .piu_form {
        display: flex;
        flex-direction: column;
        .radio_grout_wrap {
          margin-bottom: 100px;
          position: relative;
          .safe_harbor_info {
            position: absolute;
            width: 40%;
            right: -19.5%;
            top: 30px;
            display: flex;
            flex-direction: column;
            .safe_harbor_info_item {
              margin-bottom: 15px;
              display: flex;
              align-items: center;
              .safe_harbor_info_input {
                margin-right: 10px;
                display: inline-flex;
                width: 75px;
                .input {
                  &:hover .MuiInput-underline:before {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                  }
                }
              }
              .safe_harbor_info_label {
                font-weight: 600;
                color: $DARK_GREY;
              }
            }
          }
        }
        .piu_radio_group {
          flex-direction: row;
          flex-wrap: nowrap;
          //margin-bottom: 15px;
          .piu_radio_button {
            margin-right: 80px;
          }
        }
        .piu_input_container {
          padding-left: 25px;
          position: absolute;
          top: 30px;
        }
        .piu_input {
          width: 75px;
          .MuiInput-underline:before {
            left: 0;
            right: 0;
            bottom: 0;
            content: '';
            position: absolute;
            transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1)
              0ms;
            border-bottom: 1px solid rgba($BLACK, 0.12);
            pointer-events: none;
          }

          &:hover .MuiInput-underline:before {
            border-bottom: 2px solid rgba($DARK_GREEN, 0.24);
          }
          .MuiInput-underline:after {
            border-bottom: 2px solid $DARK_GREEN;
          }
          &:focus .MuiInput-underline:after {
            transform: scaleX(1);
          }
          .MuiInput-input {
            color: $DARK_GREY;
            font-family: Montserrat, sans-serif;
            font-size: 14px;
            letter-spacing: 0;
            -webkit-text-fill-color: $DARK_GREY;
            -webkit-tap-highlight-color: transparent;
            &:-webkit-autofill,
            input:focus:-webkit-autofill {
              box-shadow: 0 0 0 100px $WHITE inset;
            }
          }
        }
        .update_piu_button_container {
          max-width: 190px;
        }
      }
    }
  }
}
