.text_area_container {
  border-radius: 5px;
  padding: 15px 20px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  &.green {
    background-color: rgba(64, 227, 98, 0.1);
    & > * {
      color: $DARK_GREY;
      font-family: Montserrat, sans-serif;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 15px;
    }
  }
  &.blue {
    background-color: rgba(0, 68, 126, 0.1);
    & > * {
      color: #004680;
      font-family: Montserrat, sans-serif;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 15px;
    }
  }
}
