.title_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  //width: 100%;
  align-self: flex-start;
  .tool_tip_container {
    margin-right: 10px;
    > span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.title {
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
  line-height: 2.43rem;
  letter-spacing: 0;
  margin: unset;
  color: $DARK_BLUE;
  font-family: Montserrat, sans-serif;
  position: relative;
  display: flex;
  &.margin-right {
    margin-right: 10px;
  }
  .title_info_message_container {
    position: absolute;
    left: 130%;
    width: max-content;
    align-self: center;
  }
}
h3.title {
  font-size: 18px;
}
