.button_wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  .custom_button_container {
    border: none;
    outline: none;
    background-color: unset;
    display: flex;
    //justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 190px;
    &.green {
      height: 45px;
      background-color: $DARK_GREEN;
      border-radius: 5px;

      &:disabled {
        background-color: #a8b0b1;
        cursor: not-allowed;
      }
      & > span {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
        color: $WHITE;
      }
      &:hover,
      &:focus {
        filter: brightness(1.2);
        transition: 0.3s;
        background-color: $DARK_GREEN;
      }
    }

    &.outline_blue {
      background-color: inherit;
      border-radius: 5px;
      border: 1px solid $DARK_BLUE;
      height: 45px;
      &:disabled {
        border: 1px solid $GREY_1;
        cursor: not-allowed;
        & > span {
          color: $GREY_1;
        }
      }
      & > span {
        color: $DARK_BLUE;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
      }
      &:hover,
      &:focus {
        filter: brightness(1.2);
        transition: 0.3s;
        background-color: rgba($DARK_BLUE, 0.05);
      }
      & + .spinner::after {
        border-color: $DARK_BLUE transparent $DARK_BLUE transparent;
      }
    }

    &.link {
      width: fit-content;
      &:hover,
      &:focus {
        filter: brightness(1.2);
        transition: 0.3s;
      }
    }
  }
  .spinner {
    position: absolute;
    right: 10px;
    align-self: center;
  }
}

.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    font-size: 14px;
  }
}
