.report_search_export_section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 30px;
  //padding: 0 30px;
  .search_container {
    width: 380px;
  }
  .batches_filter_group {
    display: flex;
  }
}
