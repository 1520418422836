.batches_page_container {
  padding: 30px 42px 0;
  flex: unset;
  .batches_search_section {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    .search_container {
      flex: 0.48126;
      height: 100%;
      display: flex;
      align-items: flex-end;
      .batches_page_search_input_container {
        flex: 1;
      }
    }
    .batches_filter_group {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .new_batches_btn {
        width: 140px;
      }
    }
  }
  .batches_section,
  .invoices_section {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
