.invoice_detail_modal {
  width: 100%;
  //max-width: 940px;
  max-width: 1042px;
  padding: 30px 0;
  .invoice_detail_info_box_container {
    padding: 0 30px;
    margin-bottom: 15px;
  }
  .invoice_detail_modal_title {
    margin-bottom: 16px;
    padding: 0 30px;

    .title_info_message_container {
      left: 100%;
      display: flex;
      align-items: center;
    }
  }

  .text_fields_section {
    display: flex;
    align-items: stretch;
    flex-direction: row;
    padding: 0 30px;
    margin-bottom: 20px;
    min-height: 70px;
    overflow: hidden;
    justify-content: space-between;
    .text_fields_column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      flex: 1;
      align-items: center;
      .text_fields_item {
        &.address {
          > span {
            width: 200px;
            display: inline-block;
            white-space: break-spaces;
          }
        }
      }
    }
    .text_fields_column:first-child {
      align-items: flex-start;
      height: inherit;
    }
    .text_fields_column:last-child {
      align-items: flex-end;
      height: inherit;
    }
    // .text_fields_line {
    //   display: flex;
    //   justify-content: space-between;
    //   width: 100%;
    //   .text_fields_item {
    //     flex: 1;
    //     &.last {
    //       flex: unset;
    //     }
    //   }
    //   &.top {
    //     margin-bottom: 15px;
    //   }
    // }

    // .last {
    //   flex: 1;
    //   display: flex;
    //   align-items: flex-end;
    // }
  }
  .invoice_details_table {
    .invoice_details_table_header,
    .invoice_detail_table_row {
      .product {
        flex: 2;
      }
      .description {
        flex: 4;
      }
      .quantity {
        flex: 0.6;
      }
      .rate {
        flex: 1.2;
        text-align: right;
        justify-content: flex-end;
      }
      .amount {
        flex: 1.2;
        justify-content: flex-end;
        text-align: right;
      }
    }
  }
  .invoice_details_modal_button_section {
    display: flex;
    padding: 30px 30px 0;
    border-top: 1px solid #eeeeee;
    align-items: center;
    .invoice_details_modal_buttons_wrap {
      width: 190px;
    }
    .total {
      flex: 1;
      display: flex;
      align-items: flex-end;
      & > span:last-child {
        color: #222222;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 29px;
      }
    }
  }
  .invoice_detail_delete_button_container {
    // background: none;
    // outline: none;
    // border: none;
    // cursor: pointer;
    // color: rgba($DARK_RED, 0.8);
    // width: 20px;
    // height: 100%;
    // transition: 0.2s;
    // margin-left: 10px;
    // & > svg {
    //   width: 24px;
    //   height: 24px;
    // }
    // &:hover {
    //   filter: brightness(0.7);
    // }
    // &:active {
    //   opacity: 0.75;
    // }
    width: 30px;
    height: 30px;
    margin-left: 16px;
    .button_icon {
      color: rgba($DARK_RED, 0.8);
      font-size: 24px;
    }
    .reprocess_icon {
      color: rgba($DARK_GREEN, 0.8);

      &.rotate {
        animation: rotate 1s infinite;
        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}
