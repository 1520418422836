.alert_container {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 9999;
  width: 30%;
  min-width: 300px;
  height: auto;
  min-height: 5rem;
  max-width: 350px;
  animation: move 1s 1 ease-in-out;
  outline: none;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  //padding-right: 4rem;
  transition: opacity 0.15s linear;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  &.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
  &.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
    background-color: unset;
    border: none;
  }
  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
  }
  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  .alert_message {
    font-size: 14px;
  }
  .end_animation {
    animation: opacityAn 0.5s 1 ease-in-out;
  }

  @keyframes move {
    from {
      right: -31%;
    }
    to {
      right: 5px;
    }
  }

  @keyframes opacityAn {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
