#modal {
  position: relative;
  &.active {
    z-index: 1000;
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
  }
}
#modal:not(.active) ~ #root {
  transition: 300ms filter ease-in-out, 400ms opacity ease-out;
  filter: blur(0);
  opacity: 1;
}
#modal.active ~ #root {
  transition: 300ms filter ease-in-out, 400ms opacity ease-out;
  height: 100vh;
  overflow-y: hidden;
  //margin-right: 17px;
}
#modal ~ #root {
  filter: blur(2px);
  opacity: 0.5;
  transition: 200ms filter ease-in-out, 200ms opacity ease-out;
}

.modal_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  padding: 23px;
  background-color: rgba($BLACK, 0.4);
  animation: show-modal 0.3s 1 ease;
  @keyframes show-modal {
    from {
      opacity: 0.8;
    }
    to {
      opacity: 1;
    }
  }
}
.modal_wrap {
  // margin-right: 17px;
  background-color: $WHITE;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 5px 18px 0 rgba(0, 0, 0, 0.2);
  padding: 30px 0;
  position: relative;
  animation: show-wrap 0.3s 1 cubic-bezier(0.68, -0.55, 0.265, 1.55);
  @keyframes show-wrap {
    from {
      transform: scale(0.97);
    }
    to {
      transform: scale(1);
    }
  }
}
