.lds-dual-ring {
  display: flex;
  justify-content: center;
  align-items: center;
  &:after {
    content: ' ';
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  &.green:after {
    border: 2px solid $DARK_GREEN;
    border-color: $DARK_GREEN transparent $DARK_GREEN transparent;
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
