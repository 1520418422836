.info_box_container {
  display: flex;
  justify-content: left;
  align-items: center;
  border-radius: 5px;
  padding: 0 20px;
  background-color: rgba($DARK_BLUE, 0.1);
  overflow: hidden;
  height: 45px;
  width: 100%;
  &.warning {
    background-color: rgba(247, 40, 54, 0.1);
    .info_box_text {
      color: #f72836;
      font-family: Montserrat, sans-serif;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
    }
  }
  &.success {
    background-color: rgba($GREEN, 0.1);
    .info_box_text {
      color: $DARK_GREY;
      font-family: Montserrat, sans-serif;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
    }
  }
}

.info_box_text {
  color: $DARK_BLUE;
  font-size: 1rem;
  font-weight: bold;
}
