.users_page_container {
  padding: 30px 42px 0;
  flex: unset;
  padding-bottom: 200px;
  .users_page_search_section {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    .search_container {
      flex: 0.48126;
      height: 100%;
      display: flex;
      align-items: flex-end;
      .users_page_search_input_container {
        flex: 1;
      }
    }
    .users_page_filter_group {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .users_page_btn {
        width: 140px;
      }
    }
  }
  .users_page_users_section {
    display: flex;
    width: 100%;
  }
}
