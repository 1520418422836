.message_block_container {
  background-color: rgba(64, 227, 98, 0.1);
  padding: 18px;
  .message_block_content {
    color: $DARK_GREY;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
  }
  &.warning {
    background-color: rgba(247, 40, 54, 0.1);
    .message_block_content {
      color: #f72836;
    }
  }
}
