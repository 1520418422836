.header_container {
  height: 80px;
  width: 100%;
  background-color: $WHITE;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 42px;
  .header_section {
    height: 100%;
    &.header_logo_section {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .header_logo {
        height: auto;
        width: 200px;
      }
    }
    &.header_dashboard_menu_section {
      display: flex;
      flex-basis: 33%;
      align-items: center;
      justify-content: center;
      margin-left: -50px;
      .header_menu_item {
        height: 100%;
        min-width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $DARK_BLUE;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        &.active {
          color: $DARK_GREEN;
          position: relative;
          transition: 0.3s;
          &::before {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $DARK_GREEN;
            transition: 0.3s;
          }
        }
        &:hover {
          color: $DARK_GREEN;
        }
      }
    }

    &.header_profile_section {
      display: flex;
      align-items: center;
      position: relative;
      .button_menu {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: none;
        background-color: $GREY_2;
        outline: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background-color: $DARK_BLUE;
        }
        &.active {
          background-color: $DARK_BLUE;
        }
        .button_menu_name {
          color: $WHITE;
          font-size: 18px;
          font-weight: 600;
        }
      }
      .menu_container {
        position: absolute;
        width: 220px;
        background-color: $WHITE;
        filter: drop-shadow(0 0 5px rgba($BLACK, 0.2));
        top: 75px;
        right: 0px;
        padding: 31px 20px 0 20px;
        border-radius: 5px;
        z-index: 9999;
        display: none;
        &::before {
          position: absolute;
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 10px 10px 10px;
          border-color: transparent transparent $WHITE transparent;
          top: -10px;
          right: 15px;
        }
        &.active {
          display: flex;
          animation: open-menu 0.3s 1 ease-in-out;
        }
        .menu_wrap {
          width: 100%;
          .menu_item {
            width: 70%;
            color: $DARK_BLUE;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            cursor: pointer;
            & > img {
              margin-right: 10px;
            }
            &.logout {
              color: $RED;
            }
            &:hover {
              filter: brightness(1.8);
              transition: 0.3s;
            }
            .menu_icon {
              width: 25px;
              height: auto;
            }
          }
        }

        @keyframes open-menu {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      }
    }
  }
  .header_notification_profile_section {
    display: flex;
    align-items: center;
    .header_notification_section {
      margin-right: 8px;
      position: relative;
      .header_notification_button_menu {
        border-radius: 50%;
        width: 48px;
        height: 48px;
        border: none;
        background-color: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s;
        &:hover,
        &.open {
          background-color: #004482;
          .opacity {
            opacity: 1;
          }
          .bell-icon {
            fill: $WHITE;
          }
        }
        .notification_badge {
          height: 8px;
          width: 8px;
          background-color: #e34040;
          border-radius: 50%;
          position: absolute;
          top: 12px;
          right: 13px;
        }
      }
      .notification_menu_container {
        position: absolute;
        width: 420px;
        max-width: 420px;
        padding-top: 30px;
        z-index: 999;
        background-color: $WHITE;
        filter: drop-shadow(0 0 5px rgba($BLACK, 0.2));
        right: -117%;
        top: 59px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        &::before {
          position: absolute;
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 10px 10px 10px;
          border-color: transparent transparent $WHITE transparent;
          top: -10px;
          right: 70px;
        }
        .notification_menu_title_container {
          padding: 0 30px 24px 30px;
          border-bottom: 1px solid #eeeeee;
          .notification_menu_title {
            color: #222222;
            font-family: Montserrat, sans-serif;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 18px;
          }
        }
        .notification_list_container {
          display: flex;
          width: 100%;
          flex-direction: column;
          .notification_item_container {
            display: flex;
            flex-direction: column;
            padding: 10px 30px;
            justify-content: center;
            //height: 45px;
            border-bottom: 1px solid #eeeeee;
            .notification_item_title {
              border: none;
              outline: none;
              background-color: unset;
              cursor: pointer;
              color: #004482;
              font-size: 12px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 15px;
              text-align: left;
              &.read {
                font-weight: 600;
              }
            }
            .notification_item_time {
              color: #838a8e;
              font-size: 10px;
              font-weight: 600;
              letter-spacing: 0;
              line-height: 13px;
            }
          }
        }
      }
    }
  }
}
