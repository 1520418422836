.user_profile_header__closebtn {
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: unset;
  outline: none;
  border: none;
  width: 28px;
  height: 28px;
  cursor: pointer;
  opacity: 0.3;
  &:hover {
    opacity: 1;
    transition: 0.3s;
  }
  &:before,
  &:after {
    position: absolute;
    left: 50%;
    top: 0;
    content: ' ';
    height: 28px;
    width: 2px;
    background-color: #333;
  }
  &:before {
    transform: translate(-50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%) rotate(-45deg);
  }
}
