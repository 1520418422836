.confirm_modal_container {
  .MuiDialog-paper {
    margin-right: 17px;
    background-color: $WHITE;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0 5px 18px 0 rgba(0, 0, 0, 0.2);
    padding: 30px 0;
    position: relative;
    width: 95%;
    max-width: 460px;
    padding: 30px 0;
    margin-left: -15px;
    .confirm_modal_title_container {
      margin-bottom: 30px;
      padding: 0 30px;
      .confirm_modal_title {
        font-size: 18px;
      }
    }
    .confirmation_modal_middle_message_container {
      padding: 0 30px 30px;
      border-bottom: 1px solid #eeeeee;
      margin-bottom: 30px;
    }
    .buttons-section {
      display: flex;
      justify-content: space-between;
      padding: 0 30px;
      &.right {
        flex-direction: row-reverse;
        .yes_button {
          margin-right: unset;
        }
        .no_button {
          margin-right: 20px;
        }
      }
      .yes_button {
        margin-right: 20px;
      }
    }
  }
}
